import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { getAllData } from "../data";
import { Container } from "react-bootstrap";
import ExpandableGraph from "../components/Workflow/Graph.js";
import Filter from "../components/Workflow/Filter";
import NodeDetail from "../components/Workflow/NodeDetail";
import Labels from "../components/Workflow/Labels";
import Zoom from "../components/Workflow/Zoom";
import { DataService } from "../data/DataService";
import { KnowledgeGraphData } from "../data/DataService";
import { KnowledgeGraphData_WorkFlow } from "../data/DataService";
import { KnowledgeGraphData_WorkflowImplementations } from "../data/DataService";
import { KnowledgeGraphData_WorkflowImplementationSeller } from "../data/DataService";
import { KnowledgeGraphData_WorkflowImplementationBuyer } from "../data/DataService";
//var _ = require("lodash");

function WorkFlow() {
  const [allData, setAllData] = useState({});
  const [wfdata, setWfData] = useState([]);
  const [wfdataImp, setWfdataImp] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [item, setItem] = useState({});
  const [prunedTree, setPrunedTree] = useState({ nodes: [], links: [] });
  const [unchangedData, setUnchangedData] = useState({});
  const [show, setShow] = useState(false);
  const containerRef = useRef({});
  const dataFetchRef = useRef(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const fixValueChain = (items: any[]) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].valueChain) {
        items[i].valueChain = items[i].valueChain.replace("&amp;", "&");
      }
    }
  }

  const fetchData = async () => {
    const knowledgeGraphDataForWF = await axios.get<KnowledgeGraphData_WorkFlow>(
      "https://api.insurancegig.com/workflowapimainv2/api/workflow/getAllWorkflowKG"
    );
    let workflows: any = knowledgeGraphDataForWF.data;

    if (knowledgeGraphDataForWF.data != null) {
      const knowledgeGraphDataForWFI = await axios.get<KnowledgeGraphData_WorkflowImplementations>(
        "https://api.insurancegig.com/workflowapimainv2/api/workflow/getAllWorkflowImplementationsKG"
      );

      let workflowImplementations:any = knowledgeGraphDataForWFI.data;


      if (knowledgeGraphDataForWFI.data != null) {
        const knowledgeGraphDataForSeller = await axios.get<KnowledgeGraphData_WorkflowImplementationSeller>(
          "https://api.insurancegig.com/workflowapimainv2/api/workflow/getAllWorkflowImplementationSellerKG"
        );

        let sellers:any = knowledgeGraphDataForSeller.data;

        if (knowledgeGraphDataForSeller.data != null) {
          const knowledgeGraphDataForBuyer = await axios.get<KnowledgeGraphData_WorkflowImplementationBuyer>(
            "https://api.insurancegig.com/workflowapimainv2/api/workflow/getAllWorkflowImplementationBuyerKG"
          );

          let  buyers:any = knowledgeGraphDataForBuyer.data;

          fixValueChain(buyers);
          fixValueChain(sellers);

          let data = getAllData(workflows, workflowImplementations, sellers, buyers);

          const graphData = DataService.getGraphData({
            workflows, workflowImplementations, buyers, sellers
          }
          )

          setAllData(graphData);
          setUnchangedData(data.workflowdata);
          setWfData(data.wfdata);
          setWfdataImp(data.workflowimpdata);
          setSellers(data.sellerdata);
          setBuyers(data.buyerdata);
        }

      }

    }
  };

  // const fetchData = async () => {
  //   const knowledgeGraphData = await axios.get<KnowledgeGraphData>(
  //     // "https://api.insurancegig.com/workflowapimainv2/api/workflow/knowledgegraph"
  //     "https://localhost:44306/api/workflow/knowledgegraph"
  //   );

  //   let { workflows, workflowImplementations, buyers, sellers } = knowledgeGraphData.data;
  //   fixValueChain(buyers);
  //   fixValueChain(sellers);

  //   let data = getAllData(workflows, workflowImplementations, sellers, buyers);

  //   const graphData = DataService.getGraphData({
  //     workflows, workflowImplementations, buyers, sellers
  //   }
  //   )

  //   setAllData(graphData);
  //   setUnchangedData(data.workflowdata);
  //   setWfData(data.wfdata);
  //   setWfdataImp(data.workflowimpdata);
  //   setSellers(data.sellerdata);
  //   setBuyers(data.buyerdata);
  // };

  useEffect(() => {
    console.log("callinguseEftect");
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    fetchData();
  }, []);

  if (Object.keys(allData).length === 0) {
    return (
      <div className="loader-container">
        <img src="imgs/loader.gif" />
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Container className="position-absolute" style={{display: "none"}}>
            <Zoom prunedTree={prunedTree} containerRef={containerRef} />
          </Container>
          <Container className="position-absolute">
            <Filter
              wfdata={wfdata}
              wfdataImp={wfdataImp}
              sellers={sellers}
              buyers={buyers}
              setAllData={setAllData}
              unchangedData={unchangedData}
              resetDataCallback={() => { fetchData(); }}
              setIsFiltered={setIsFiltered}
            />
          </Container>
          <Container className="position-absolute">
            <NodeDetail
              item={item}
              show={show}
              setShow={setShow}
              wfdata={wfdata}
              allData={allData}
              setAllData={setAllData}
              wfdataImp={wfdataImp}
              sellers={sellers}
              buyers={buyers}
            />
          </Container>
          <Container className="position-absolute curser-move ">
            <Labels />
          </Container>
          <Container className="kg container-fluid" fluid={true}>
            <ExpandableGraph
              graphData={allData}
              setItem={setItem}
              containerRef={containerRef}
              setShow={setShow}
              prunedTree={prunedTree}
              setPrunedTree={setPrunedTree}
              isFiltered={isFiltered}
            />
          </Container>
        </div>
      </div>
    );
  }
}

export default WorkFlow;
